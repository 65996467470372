<template>
  <div class="flex w-auto container-search">
    <transition-group class="search" name="slide-fade">
      <input
        class="input-search rounded-full w-72 pl-5 shadow focus_outline-none"
        key="input"
        type="text"
        :placeholder="$t('map.search')"
        v-model="mapSearch"
        style="position: relative"
        @keydown="getSearch"
      />
      <div class="result-wrapper" key="result" v-show="searchArr.length > 0">
        <div class="result" v-for="(item, index) in searchArr" :key="index">
          <span @click="centerMap(item)">
            {{ item.name }}, {{ item.description }}
          </span>
        </div>
      </div>
    </transition-group>
    <button
      class="relative button-search transition-colors duration-300 bg-white hover_bg-reallylightblue shadow focus_outline-none flex items-center justify-center"
      type="button"
      style="right: 0"
      slot="reference"
      @click="getSearch"
    >
      {{ $t('search') }}
    </button>
  </div>
</template>

<script>
import { trackingApi } from '@/api'
import { mapMutations } from 'vuex'

export default {
  data: () => {
    return {
      showSearch: false,
      mapSearch: '',
      searchArr: []
    }
  },

  methods: {
    ...mapMutations('login', ['CENTER_MAP_CLEAR']),
    closeSearch() {
      if (this.showSearch) {
        this.CENTER_MAP_CLEAR()
      }
      this.showSearch = !this.showSearch
    },

    centerMap(item) {
      this.$emit('center-map', [item.pos[1], item.pos[0]])
      this.searchArr = []
    },

    async getSearch(keyEvent) {
      if (keyEvent.key === 'Enter' || keyEvent.target.type === 'button') {
        await trackingApi
          .geosearch(this.mapSearch)
          .then((resp) => {
            if (resp.data.length === 0) {
              this.$showError(this.$t('map.adrress_not_found'))
            }
            this.searchArr = resp.data
          })
          .catch((err) => {
            this.$showError(err.response.data.message)
          })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.container-search
  align-items center
.button-search
  height 28px
  padding 9px 20px
  background-color #446CA7
  color white
  font-weight 600
  font-size 12px
  line-height 15px
  border-radius 8px

.input-search
  height 36px
  border-radius 8px
  right -79px
  font-weight 600
  font-size 12px
  line-height 15px
  &::before
    content ''
    background url('/components/map/tools/images/SearchIcon.svg') no-repeat center center

.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}
.result-wrapper
  position absolute
  left 79px
  margin-top 3px
  width 288px
  height auto
  max-height 310px
  overflow-y auto
  background-color #fff
  border-radius 12px
  .result
    padding 17px 17px 0 17px
    cursor pointer
    &:last-child
      padding-bottom 15px
</style>
